import React from 'react'
import './style.scss'
import { RouteWithSubRoutes } from '../shared'
import { Redirect, Switch } from 'react-router-dom'

export default function P5jsHome(props) {
  return (
    <div>
      <Switch>
          {
            props.routes.map(route => (
              <RouteWithSubRoutes
                key={route.path}
                {...route}/>
            ))
          }
          <Redirect to="/p5js/browse"/>
      </Switch>
    </div>
  )
}
