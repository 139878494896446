import React from 'react'
import MenuItem from './MenuItem'

export default function Menu(props) {
  return (
    <ul className="sw-menu">
      <MenuItem label="Home" to="/" exact/>
      <MenuItem label="p5 Js" to="/p5js"/>
      <MenuItem label="D3 Js" to="/d3js"/>
      <MenuItem label="React" to="/reactjs"/>
      <MenuItem label="Javascript" to="/js"/>
      <MenuItem label="Designs" to="/designs"/>
    </ul>
  )
}
