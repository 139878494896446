import Home from "./module/Home"
import DesignsHome from "./module/DesignsHome";
import P5jsHome from "./module/P5jsHome";
import P5jsBrowse from "./module/P5jsBrowse";
import JavascriptHome from "./module/JavascriptHome";
import D3js from "./module/D3js";
import ReactjsHome from "./module/ReactjsHome";
import PageNotFound from "./module/PageNotFound";
import ProjectDetails from "./module/Projects/ProjectDetails";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/designs",
    component: DesignsHome,
  },
  {
    path: "/p5js",
    component: P5jsHome,
    routes: [
      {
        path: "/p5js/browse",
        component: P5jsBrowse,
      },
      {
        path: "/p5js/:projectId",
        component: ProjectDetails,
      },
    ],
  },
  {
    path: "/js",
    component: JavascriptHome,
  },
  {
    path: "/d3js",
    component: D3js,
  },
  {
    path: "/reactjs",
    component: ReactjsHome,
  },
  {
    path: "**",
    component: PageNotFound,
  }
];

export default routes;