import React from 'react'
import { NavLink } from 'react-router-dom';

export default function MenuItem(props) {
  const {
    label = "label",
    ...otherProps
  } = props;
  return (
    <NavLink 
      className={"sw-menu_item"} 
      activeClassName={"sw-menu_item--active"} 
      {...otherProps}>
      {label}
    </NavLink>
  )
}
