import React from 'react';
import "./style.scss";
import {CompanyLogo, Menu, Card} from "../ui"
import { Link } from 'react-router-dom';

export default function Header(props) {
  return (
    <Card className="sw-header">
      <Link to="/"><div className="sw-header_logo"><CompanyLogo/></div></Link>
      <div className="sw-header_menu"><Menu/></div>
      <div className="sw-header_profile">Profile</div>
    </Card>
  )
}
