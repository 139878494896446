import React from 'react'
import Card from './Card';
import { classnames } from '../../commons/utils';

export default function ProjectSnapCard(props) {
  const {
    title,
    desc,
    timestamp,
    bgImgUrl,
    bgColor,
    color,
    ...otherProps
  } = props;
  return (
    <Card className={
        classnames([
          otherProps.onClick ? 'sw-snapcard--hoverable' : 'sw-snapcard'
        ])
      } 
      style={{color}} {...otherProps}>
      <div className="sw-snapcard_head" style={{backgroundImage:`url(${bgImgUrl})`}}>
        <div className="sw-snapcard_head_tint"
          style={{background:`linear-gradient(to bottom, transparent 0%, ${bgColor} 90%)`}}
        /> 
        <h3 className="sw-snapcard_title">{title}</h3>
      </div>
      <div className="sw-snapcard_body"
        style={{backgroundColor: bgColor}}>
        <p>{desc.substring(0, 108)}...</p>
        <div className="sw-snapcard_timestamp"><h5>{timestamp}</h5></div>
      </div>
    </Card>
  )
}