import React from 'react'
import './style.scss'
import {Section,ProjectSnapCard} from '../ui'

export default function Home() {
  return (
    <div className="sw-pad">
      <Section label={"Home"}>
        <ProjectSnapCard
          onClick={() => {console.log("OMMMMMM")}}
          title={"Escape through space"}
          subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          desc={"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}
          timestamp={"09/04/2020"}
          bgImgUrl={"https://images.unsplash.com/photo-1518495973542-4542c06a5843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=120"}
          bgColor="black"
          color="wheat" />
        <ProjectSnapCard
          disabled = {true}
          title={"Crack the break"}
          subtitle={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          desc={"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}
          timestamp={"10/04/2020"}
          bgImgUrl={"https://images.unsplash.com/photo-1518495973542-4542c06a5843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=300&q=120"}
          bgColor="pink"
          color="black" />
      </Section>
    </div>
  )
}
