export default function classnames(...args) {
  let classnamesFiltered;
  if(args) {
    classnamesFiltered = args[0].filter(classname => {
      if(typeof classname === 'string'){
        return true;
      }
      return false;
    });
    return classnamesFiltered.join(' ');
  } else {
    return ''
  }
}