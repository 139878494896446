import React from 'react'

export default function Button(props) {
  const {
    type="primary",
    children,
    ...restProps
  } = props;
  let ui;
  switch (type) {
    case "link":
      ui = <button className={`sw-btn_${type}`} {...restProps}>{children}</button>
      break;
    default :
      ui = <button className={`sw-btn_${type}`} {...restProps}>{children}</button>
  }
  return ui;
}
