import React , { useRef, useState, useEffect } from 'react'
import './style.scss'
import p5 from 'p5'
import { Card } from '../ui'
import InputRange from '../ui/InputRange';

export default function Canvas(props) {
  const canvasRef = useRef();
  const [canvasSet, setCanvasSet] = useState(false);
  const [canvas, setCanvas] = useState({
    width: 10,
    height: 10,
  })
  const stars = [];
  const CONST_INIT_SPEED = 24;
  function sketch(p) {
    p.setup = () => {
      p.createCanvas(canvas.width, canvas.height);
      p.background(0);
      for(let i = 0; i<1500; i++) {
        stars.push(Star(p));
      }
    }
    p.draw = () => {
      p.translate(canvas.width/2, canvas.height/2);
      stars.forEach(star => {
        star.update()
        star.show()
      })
      p.background(0, 0, 0, 80)
    }
  }
  function Star(p) {
    let size = Math.random() * 3;
    let x = Math.random() * (canvas.width - (-canvas.width)) + (-canvas.width);
    let y = Math.random() * (canvas.height - (-canvas.height)) + (-canvas.height);
    let z = Math.random() * canvas.width;
    function show() {
      p.fill(255, 255, 0); 
      p.noStroke();
      const sx = p.map(x/z, 0, 1, 0, canvas.width);
      const sy = p.map(y/z, 0, 1, 0, canvas.height);
      p.ellipse(sx, sy, size, size);
    }
    function update() {
      let speed = CONST_INIT_SPEED;
      if(p.mouseX > 0 && p.mouseX < canvas.width) {
        speed = p.map(p.mouseX, 0, canvas.width, speed, 200)
      }
      z = z - speed/10 ;
      size += speed/1000;
      if(z < 1) {
        size = Math.random();
        z = canvas.width;
        x = Math.random() * (canvas.width - (-canvas.width)) + (-canvas.width);
        y = Math.random() * (canvas.height - (-canvas.height)) + (-canvas.height);
      }
    }
    return {
      x,
      y,
      z,
      size,
      show,
      update,
    }
  }
  // EFFECTS
  useEffect(() => {
    setCanvas({
      width: canvasRef.current.clientWidth,
      height: canvasRef.current.clientHeight
    })
    setCanvasSet(true);
  }, []);
  useEffect(() => {
    if(canvasSet) {
      new p5(sketch, canvasRef.current);
    }
  }, [canvasSet]);
  console.log("[Canvas]: re-rendered")
  return (
    <Card className="sw-canvas">
      <div className="sw-canvas_ref" ref={canvasRef} />
    </Card>
  )
}
