import React from 'react'
import './style.scss'
import { Section } from '../ui'

export default function D3js() {
  return (
    <div className="sw-pad">
      <Section label={"React JS"}>
      </Section>
    </div>
  )
}
