import React from 'react';
import './style.scss';
import Header from "../Header"
import routesConfig from '../../routesConfig';
import { 
  BrowserRouter as Router,
  Switch
 } from 'react-router-dom';
import { RouteWithSubRoutes } from '../shared';

function App() {
  return (
    <div className="app">
      <Router>
        <Header/>
        <div className="app__content">
          <Switch>
            {routesConfig.map(route => (
              <RouteWithSubRoutes key={route.path}  {...route}/>
            ))}
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
