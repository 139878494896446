import React from 'react'

export default function Section(props) {
  const {
    label = "label",
    children
  } = props;
  return (
    <section className="sw-section">
      <h1 className="sw-section_label"><b>{label}</b></h1>
      <div className="sw-section_content">{children}</div>
    </section>
  )
}
