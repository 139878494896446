import React from 'react';
import { ReactComponent as CompanyLogoSVG} from '../../assets/icons/company-logo.svg';

export default function CompanyLogo() {
  return (
    <div className="sw-logo">
      <div className="sw-logo_svg"><CompanyLogoSVG/></div>
      <div className="sw-logo_label">swavikas</div>
    </div>
  )
}
