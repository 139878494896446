import React from 'react'
import { classnames } from '../../commons/utils'

export default function Card(props) {
  const {
    disabled,
    children,
    className,
    ...otherProps
  } = props;
  return (
    <div className={classnames([
            disabled ? 'sw-card--disabled'
          : otherProps.onClick ? 'sw-card--clickable'
          : 'sw-card',
          className
        ])}
        {...otherProps}>
      {children}
    </div>
  )
}
