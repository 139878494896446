import React from 'react'
import './style.scss'
import { Section } from '../ui'

export default function DesignsHome() {
  return (
    <div className="sw-pad">
      <Section label={"Designs"}>
      </Section>
    </div>
  )
}
